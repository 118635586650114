/* 
EN: This file is generated automatically, don't edit manually. Please modify app-dependant.json if necessary and then restart app, this file will be updated automatically.
VI: File này được sinh tự động, đừng sửa trực tiếp vào đây, nếu cần thì hãy sửa file app-dependant.json sau đó chạy lại app, file này sẽ được tự động update theo.
*/

export class AppConst {
  public static readonly Version: string = "0.6.10";
  public static readonly BuildWhen: string = "2024-06-12 14:12:18";
  public static readonly devFeatureBranch: string = "feature/WPD-2386-optimize-performance-bid-detail";
  public static readonly devFeatureBuildBy: string = "Nguyen The Son <nguyentheson.ict@gmail.com>";
  public static readonly gaTrackingId: string = "";
  public static readonly devFeature: string = "";
}
